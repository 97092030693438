const DEFAULT_FALLBACKS = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Segoe UI Emoji',
  'Apple Color Emoji',
  'Segoe UI Symbol',
];

const ACCENT_FONTS = [
  'Anton',
  'Archivo',
  'General Sans',
  'Gilda',
  'Cabinet',
  'Clash',
  'Panchang',
  'Satoshi',
] as const;
export type AccentFont = (typeof ACCENT_FONTS)[number];
export const isAccentFont = (font: string): font is AccentFont => {
  return (ACCENT_FONTS as readonly string[]).includes(font);
};

const BODY_FONTS = [
  'Archivo',
  'Cabinet',
  'Clash',
  'Epilogue',
  'General Sans',
  'Satoshi',
  'Space Grotesk',
  'Switzer',
  'Work Sans',
] as const;
export type BodyFont = (typeof BODY_FONTS)[number];
export const isBodyFont = (font: string): font is BodyFont => {
  return (BODY_FONTS as readonly string[]).includes(font);
};

const HEADLINE_FONTS = [
  'Archivo',
  'Butler',
  'Bricolage',
  'Cabinet',
  'Clash',
  'Epilogue',
  'Gambarino',
  'General Sans',
  'Gilda',
  'Lora',
  'Neco',
  'Panchang',
  'Satoshi',
  'Switzer',
  'Syne',
  'Zodiak',
] as const;

export type HeadlineFont = (typeof HEADLINE_FONTS)[number];
export const isHeadlineFont = (font: string): font is HeadlineFont => {
  return (HEADLINE_FONTS as readonly string[]).includes(font);
};

const ALL_FONTS_COMBINED = [
  ...ACCENT_FONTS,
  ...BODY_FONTS,
  ...HEADLINE_FONTS,
  'Inter',
  'Mono',
] as const;
export type AllFonts = (typeof ALL_FONTS_COMBINED)[number];

type BuildFontStackFunction = (
  fontsArray: string[],
  options?: { useDefaultFallbacks?: boolean },
) => string;

type FontStack<T = AllFonts[keyof AllFonts]> = {
  name: T;
  value: string;
};

export type FontStacks = {
  anton: FontStack<'Anton'>;
  archivo: FontStack<'Archivo'>;
  bricolage: FontStack<'Bricolage'>;
  butler: FontStack<'Butler'>;
  cabinet: FontStack<'Cabinet'>;
  clashDisplay: FontStack<'Clash'>;
  epilogue: FontStack<'Epilogue'>;
  gambarino: FontStack<'Gambarino'>;
  generalSans: FontStack<'General Sans'>;
  gilda: FontStack<'Gilda'>;
  inter: FontStack<'Inter'>;
  lora: FontStack<'Lora'>;
  mono: FontStack<'Mono'>;
  neco: FontStack<'Neco'>;
  panchang: FontStack<'Panchang'>;
  satoshi: FontStack<'Satoshi'>;
  spaceGrotesk: FontStack<'Space Grotesk'>;
  switzer: FontStack<'Switzer'>;
  syne: FontStack<'Syne'>;
  workSans: FontStack<'Work Sans'>;
  zodiak: FontStack<'Zodiak'>;
};

const buildFontStack: BuildFontStackFunction = (fontsArray, options = {}) => {
  const { useDefaultFallbacks = true } = options;
  const resolvedFontsArray = [
    ...fontsArray,
    ...(useDefaultFallbacks ? DEFAULT_FALLBACKS : []),
  ];
  return resolvedFontsArray
    .map((font) => (font.includes(' ') ? `"${font}"` : font))
    .join(', ');
};

export const fontStacks: FontStacks = {
  anton: {
    name: 'Anton',
    value: buildFontStack(['Anton'], { useDefaultFallbacks: true }),
  },
  archivo: {
    name: 'Archivo',
    value: buildFontStack(['Archivo'], { useDefaultFallbacks: true }),
  },
  bricolage: {
    name: 'Bricolage',
    value: buildFontStack(['Bricolage'], { useDefaultFallbacks: true }),
  },
  butler: {
    name: 'Butler',
    value: buildFontStack(['Butler'], { useDefaultFallbacks: true }),
  },
  cabinet: {
    name: 'Cabinet',
    value: buildFontStack(['Cabinet'], { useDefaultFallbacks: true }),
  },
  clashDisplay: {
    name: 'Clash',
    value: buildFontStack(['Clash'], { useDefaultFallbacks: true }),
  },
  epilogue: {
    name: 'Epilogue',
    value: buildFontStack(['Epilogue'], { useDefaultFallbacks: true }),
  },
  gambarino: {
    name: 'Gambarino',
    value: buildFontStack(['Gambarino'], { useDefaultFallbacks: true }),
  },
  generalSans: {
    name: 'General Sans',
    value: buildFontStack(['General Sans'], { useDefaultFallbacks: true }),
  },
  gilda: {
    name: 'Gilda',
    value: buildFontStack(['Gilda'], { useDefaultFallbacks: true }),
  },
  inter: {
    name: 'Inter',
    value: buildFontStack(['Inter'], { useDefaultFallbacks: true }),
  },
  lora: {
    name: 'Lora',
    value: buildFontStack(['Lora'], { useDefaultFallbacks: true }),
  },
  mono: {
    name: 'Mono',
    value: buildFontStack(
      [
        'IBM Plex Mono',
        'SFMono-Regular',
        'Consolas',
        'Liberation Mono',
        'Menlo',
        'Courier',
        'monospace',
      ],
      { useDefaultFallbacks: true },
    ),
  },
  neco: {
    name: 'Neco',
    value: buildFontStack(['Neco'], { useDefaultFallbacks: true }),
  },
  panchang: {
    name: 'Panchang',
    value: buildFontStack(['Panchang'], { useDefaultFallbacks: true }),
  },
  satoshi: {
    name: 'Satoshi',
    value: buildFontStack(['Satoshi'], { useDefaultFallbacks: true }),
  },
  spaceGrotesk: {
    name: 'Space Grotesk',
    value: buildFontStack(['Space Grotesk'], { useDefaultFallbacks: true }),
  },
  switzer: {
    name: 'Switzer',
    value: buildFontStack(['Switzer'], { useDefaultFallbacks: true }),
  },
  syne: {
    name: 'Syne',
    value: buildFontStack(['Syne'], { useDefaultFallbacks: true }),
  },
  workSans: {
    name: 'Work Sans',
    value: buildFontStack(['Work Sans'], { useDefaultFallbacks: true }),
  },
  zodiak: {
    name: 'Zodiak',
    value: buildFontStack(['Zodiak'], { useDefaultFallbacks: true }),
  },
};

export const fonts = {
  accent: fontStacks.inter.value,
  anton: fontStacks.anton.value,
  archivo: fontStacks.archivo.value,
  body: fontStacks.inter.value,
  bricolage: fontStacks.bricolage.value,
  butler: fontStacks.butler.value,
  cabinet: fontStacks.cabinet.value,
  clashDisplay: fontStacks.clashDisplay.value,
  epilogue: fontStacks.epilogue.value,
  gambarino: fontStacks.gambarino.value,
  generalSans: fontStacks.generalSans.value,
  gilda: fontStacks.gilda.value,
  heading: fontStacks.inter.value,
  inter: fontStacks.inter.value,
  lora: fontStacks.lora.value,
  mono: fontStacks.mono.value,
  neco: fontStacks.neco.value,
  panchang: fontStacks.panchang.value,
  satoshi: fontStacks.satoshi.value,
  spaceGrotesk: fontStacks.spaceGrotesk.value,
  switzer: fontStacks.switzer.value,
  syne: fontStacks.syne.value,
  workSans: fontStacks.workSans.value,
  zodiak: fontStacks.zodiak.value,
};
