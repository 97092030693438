import { createNetwork } from './network.js';
import { Environment, RecordSource, Store } from 'relay-runtime';
import { logFunction } from 'relay-sentry';

const IS_SERVER = typeof window === typeof undefined;

export const createEnvironment = () => {
  const network = createNetwork();
  const environment = new Environment({
    isServer: IS_SERVER,
    log: logFunction(),
    network,
    store: new Store(new RecordSource(), {}),
  });
  // @ts-expect-error - Overwriting cache hackery again
  environment.getNetwork().responseCache = network.responseCache;
  return environment;
};
