/* eslint-disable @contra/enforce-stitches-tokens */
import { pxToRem } from '@/utilities/pxToRem.js';

export const sizes = {
  '0_2': pxToRem(0.2),
  '0_5': pxToRem(0.5),
  '0_15': pxToRem(0.15),
  '0_25': pxToRem(0.25),
  '0_75': pxToRem(0.75),
  1: pxToRem(1),
  '1_2': pxToRem(1.2),
  '1_5': pxToRem(1.5),
  2: pxToRem(2),
  3: pxToRem(3),
  4: pxToRem(4),
  5: pxToRem(5),
  6: pxToRem(6),
  7: pxToRem(7),
  8: pxToRem(8),
  10: pxToRem(10),
  11: pxToRem(11),
  12: pxToRem(12),
  13: pxToRem(13),
  14: pxToRem(14),
  16: pxToRem(16),
  18: pxToRem(18),
  19: pxToRem(19),
  20: pxToRem(20),
  21: pxToRem(21),
  22: pxToRem(22),
  23: pxToRem(23),
  24: pxToRem(24),
  26: pxToRem(26),
  28: pxToRem(28),
  30: pxToRem(30),
  32: pxToRem(32),
  34: pxToRem(34),
  36: pxToRem(36),
  38: pxToRem(38),
  40: pxToRem(40),
  44: pxToRem(44),
  46: pxToRem(46),
  48: pxToRem(48),
  50: pxToRem(50),
  54: pxToRem(54),
  56: pxToRem(56),
  58: pxToRem(58),
  60: pxToRem(60),
  62: pxToRem(62),
  64: pxToRem(64),
  68: pxToRem(68),
  70: pxToRem(70),
  72: pxToRem(72),
  76: pxToRem(76),
  80: pxToRem(80),
  86: pxToRem(86),
  88: pxToRem(88),
  90: pxToRem(90),
  96: pxToRem(96),
  100: pxToRem(100),
  103: pxToRem(103),
  104: pxToRem(104),
  108: pxToRem(108),
  112: pxToRem(112),
  114: pxToRem(114),
  116: pxToRem(116),
  118: pxToRem(118),
  120: pxToRem(120),
  122: pxToRem(122),
  124: pxToRem(124),
  128: pxToRem(128),
  130: pxToRem(130),
  136: pxToRem(136),
  140: pxToRem(140),
  145: pxToRem(145),
  148: pxToRem(148),
  150: pxToRem(150),
  155: pxToRem(155),
  160: pxToRem(160),
  166: pxToRem(166),
  168: pxToRem(168),
  170: pxToRem(170),
  180: pxToRem(180),
  184: pxToRem(184),
  192: pxToRem(192),
  200: pxToRem(200),
  204: pxToRem(204),
  208: pxToRem(208),
  220: pxToRem(220),
  232: pxToRem(232),
  240: pxToRem(240),
  280: pxToRem(280),
  288: pxToRem(288),
  296: pxToRem(296),
  300: pxToRem(300),
  316: pxToRem(316),
  330: pxToRem(330),
  340: pxToRem(340),
  376: pxToRem(376),
  384: pxToRem(384),
  400: pxToRem(400),
  408: pxToRem(408),
  435: pxToRem(435),
  448: pxToRem(448),
  450: pxToRem(450),
  500: pxToRem(500),
  518: pxToRem(518),
  544: pxToRem(544),
  600: pxToRem(600),
  608: pxToRem(608),
  640: pxToRem(640),
  680: pxToRem(680),
  800: pxToRem(800),
  816: pxToRem(816),
  892: pxToRem(892),
  1_024: pxToRem(1_024),
  1_064: pxToRem(1_064),
  safe1px: `abs(max(${pxToRem(1)}, 1px))`,
  safe2px: `abs(max(${pxToRem(2)}, 2px))`,
};
