import { logger } from '@/services/logger.js';
import { useMount } from '@contra/react-hooks/useMount';
import { createContext, type ReactNode, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const log = logger.child({
  namespace: 'EnvironmentContext',
});

type EnvironmentContextValue = {
  isPlaywrightTestEnvironment: boolean;
  stableApplicationSessionId: string;
};

export const EnvironmentContext = createContext<EnvironmentContextValue | null>(
  null,
);

export const EnvironmentProvider = ({
  children,
  isPlaywrightTestEnvironment,
}: {
  readonly children: ReactNode;
  readonly isPlaywrightTestEnvironment: boolean;
}) => {
  const value = useMemo(
    () => ({
      isPlaywrightTestEnvironment,
      stableApplicationSessionId: uuidv4(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useMount(() => {
    if (isPlaywrightTestEnvironment) {
      log.info('Playwright test environment detected');
    }
  });

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};
