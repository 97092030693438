import { colors } from '@/stitches/colorPrimitives.js';
import { styled } from '@/stitches/index.js';

const Svg = styled('svg', {
  height: 0,
  opacity: 0,
  pointerEvents: 'none',
  position: 'fixed',
  userSelect: 'none',
  visibility: 'hidden',
  width: 0,
  zIndex: -99_999,
});

export const SvgDefs = () => {
  return (
    <Svg>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradientOrangeYellow"
          x1="0"
          x2="100"
          y1="0"
          y2="100"
        >
          <stop
            offset="0"
            stopColor={`${colors.brandOrange}`}
          />
          <stop
            offset="1"
            stopColor={`${colors.brandYellow}`}
          />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradientOrangePurple"
          x1="0"
          x2="100"
          y1="0"
          y2="100"
        >
          <stop
            offset="0"
            stopColor={`${colors.brandOrange}`}
          />
          <stop
            offset="1"
            stopColor={`${colors.brandPurple}`}
          />
        </linearGradient>

        <radialGradient
          cx="-2%"
          id="radialGradientFullWheel"
          r="90%"
        >
          <stop
            offset="0%"
            stopColor="#61A6F2"
          />
          <stop
            offset="33%"
            stopColor="#8F6FDB"
          />
          <stop
            offset="66%"
            stopColor="#FF5A5E"
          />
          <stop
            offset="100%"
            stopColor="#F2C94C"
          />
        </radialGradient>

        <linearGradient id="gradientPaymentTierModal">
          <stop
            offset="30%"
            stopColor="#BEA8F2"
          />
          <stop
            offset="50%"
            stopColor="#F5F1FD"
            stopOpacity="0.15"
          />
          <stop
            offset="80%"
            stopColor="#FFBE59"
          />
        </linearGradient>

        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradientFullWheel"
          x1="0"
          x2="100"
          y1="0"
          y2="100"
        >
          <stop
            offset="0%"
            stopColor="#F3CA4D"
          />
          <stop
            offset="42%"
            stopColor="#FF989A"
          />
          <stop
            offset="100%"
            stopColor="#CBABFF"
          />
        </linearGradient>

        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradientCard"
          x1="0"
          x2="100"
          y1="0"
          y2="100"
        >
          <stop
            offset="0"
            stopColor={`${colors.cardGradient1}`}
          />
          <stop
            offset="1"
            stopColor={`${colors.cardGradient2}`}
          />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="black"
          x1="0"
          x2="100"
          y1="0"
          y2="100"
        >
          <stop
            offset="0"
            stopColor="black"
          />
        </linearGradient>
      </defs>
    </Svg>
  );
};
