import { animated } from '@/components/animated/animated.js';
import { ContraLogoSuspenseLoaderIcon } from '@/components/Icons/ContraLogoSuspenseLoaderIcon.js';
import { keyframes, styled } from '@/stitches/index.js';

const RelativelyPositionedMotionBox = styled(animated.div, {
  position: 'relative',
});

const AppLoaderPage = styled(animated.div, {
  alignItems: 'center',
  backgroundColor: '$pageBackground',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  padding: '0 $32',
  transition: 'background-color 500ms ease',
  width: '100vw',
});

const pulseKeyframes = keyframes({
  '0%': {
    opacity: 1,
  },
  '50%': {
    opacity: 0.5,
  },
  '100%': {
    opacity: 1,
  },
});

const PulsingContraLogoSuspenseLogo = styled(ContraLogoSuspenseLoaderIcon, {
  animation: `${pulseKeyframes} 1.5s ease-in infinite`,
  color: '$defaultFont',
  transition: 'color 500ms ease',
});

const animationVariant = {
  hidden: { opacity: 0, transition: { duration: 0.9 } },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
};

export const AppLoader = () => {
  return (
    <RelativelyPositionedMotionBox
      animate="show"
      exit="hidden"
      initial="hidden"
      variants={animationVariant}
    >
      <AppLoaderPage>
        <PulsingContraLogoSuspenseLogo size={60} />
      </AppLoaderPage>
    </RelativelyPositionedMotionBox>
  );
};
