import { createEnvironment } from './environment.js';
import { AppLoader } from '@/components/Loader/AppLoader.js';
import { type NextComponentType, type NextPageContext } from 'next';
import { Suspense, useMemo } from 'react';
import {
  type Environment,
  RelayEnvironmentProvider,
  useRelayEnvironment,
} from 'react-relay';
import {
  type GraphQLResponse,
  type RequestParameters,
  type Variables,
} from 'relay-runtime';

type PreloadedQuery = {
  params: RequestParameters;
  response: GraphQLResponse;
  variables: Variables;
};

type QueryReference = {
  environment: Environment;
  fetchKey: string | null;
  fetchPolicy: 'store-or-network';
  isDisposed: false;
  kind: 'PreloadedQuery';
  name: string;
  variables: Variables;
};

type QueryReferences = { queryRefs: Record<string, QueryReference> };
export type PreloadedQueries = {
  preloadedQueries: Record<string, PreloadedQuery>;
};

const Hydrate = ({
  Component,
  props,
}: {
  readonly Component: NextComponentType<NextPageContext, {}, QueryReferences>;
  readonly props: { preloadedQueries: Record<string, PreloadedQuery> };
}) => {
  const environment = useRelayEnvironment();

  const transformedProps = useMemo<QueryReferences>(() => {
    const queryReferences: QueryReferences['queryRefs'] = {};

    if (!props) {
      return { queryRefs: queryReferences };
    }

    const { preloadedQueries, ...otherProps } = props;
    if (!preloadedQueries) {
      return { queryRefs: queryReferences, ...otherProps };
    }

    for (const [queryName, { params, response, variables }] of Object.entries(
      preloadedQueries,
    )) {
      // Set response in cache
      environment
        .getNetwork()
        // @ts-expect-error - seems to be a private untyped api 🤷‍♂️
        .responseCache.set(params.id, variables, response);

      // Prepare a query reference to be used with Relay hooks on client
      queryReferences[queryName] = {
        environment,
        fetchKey: params.id,
        fetchPolicy: 'store-or-network',
        isDisposed: false,
        kind: 'PreloadedQuery',
        name: params.name,
        variables,
      };
    }

    return { ...otherProps, queryRefs: queryReferences };
  }, [environment, props]);

  return <Component {...transformedProps} />;
};

const relayEnvironment = createEnvironment() as Environment;

export const RelayContainer = ({
  Component,
  props,
}: {
  readonly Component: NextComponentType<NextPageContext, {}, QueryReferences>;
  readonly props: PreloadedQueries;
}) => {
  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <Suspense fallback={<AppLoader />}>
        <Hydrate
          Component={Component}
          props={props}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};
