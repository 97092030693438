export const colors = {
  black: '#14171F',
  blue05: '#EBF1FD',
  blue10: '#D7E3FC',
  blue20: '#AFC6F8',
  blue30: '#88AAF5',
  blue40: '#608DF1',
  blue50: '#2464E7',
  blue60: '#1650CF',
  blue70: '#1743A4',
  blue80: '#0B2866',
  blue90: '#071D4A',
  brandBlack: '#131313',
  brandBlue: '#6ca3e0',
  brandGreen: '#d2d943',
  brandLightGray: '#f6f6f6',
  brandOrange: '#ff9e31',
  brandPurple: '#6A57E3',
  brandSalmon: '#f2a28a',
  brandWatermelon: '#ff5a5e',
  brandYellow: '#f2c94c',
  cardGradient1: 'rgba(255, 255, 255, 0.21)',
  cardGradient2: 'rgba(255, 255, 255, 0.07)',
  defaultFont: '$uiBlackHighEmphasis',
  gray05: '#F9FAFB',
  gray10: '#F5F6F9',
  gray20: '#F2F4F7',
  gray30: '#E5E7EB',
  gray40: '#D0D4DC',
  gray50: '#9BA2B0',
  gray60: '#677084',
  gray70: '#4A5264',
  gray80: '#373F51',
  gray90: '#222834',
  gray95: '#1C212B',
  green05: '#E8F6EB',
  green10: '#CFF0D7',
  green20: '#BAE5C8',
  green30: '#8CD4A4',
  green40: '#5EC37F',
  green50: '#089F40',
  green60: '#307F4A',
  green70: '#21713B',
  green80: '#1B4729',
  green90: '#102A19',
  inverseDefaultFont: '$white',
  overlay10: 'rgba(19,19,19,0.5)',
  overlay20: 'rgba(0,0,0,0.77)',
  pageBackground: '$gray20',
  primaryGradient1: '#932F64',
  primaryGradient2: '#2F59B5',
  red05: '#FEF3F2',
  red10: '#FEE4E2',
  red20: '#FECDCA',
  red30: '#FDA29B',
  red40: '#F97066',
  red50: '#F04438',
  red60: '#D92D20',
  red70: '#B42318',
  red80: '#912018',
  red90: '#7A271A',
  // TODO: Document how this is used.
  // Also give a reasonable default value.
  secondaryFont: '#FF0000',
  socialInvision: '#dc395f',
  socialReact: '#53c1de',
  socialTwitter: '#4aa1eb',
  uiBlackDisabled: 'rgba(19,19,19,0.4)',
  uiBlackHighEmphasis: '#131313',
  uiBlackMediumEmphasis: 'rgba(19,19,19,0.67)',
  uiErrorFocus: '#ce0e19',
  uiErrorRegular: '#e90f1c',
  uiFilterGreen: '#cdedc7',
  uiInputBackground: 'rgba(242,242,242,0.4)',
  uiSelected: '#dfeaf9',
  underlineEmphasis: 'rgba(145, 152, 189, 0.28)',
  white: '#FFFFFF',
  white10: 'rgba(255,255,255,0.36)',
  white20: 'rgba(255,255,255,0.43)',
  white30: 'rgba(255,255,255,0.63)',
  white40: 'rgba(255,255,255,0.86)',
  yellow05: '#FFFAEB',
  yellow10: '#FEF0C7',
  yellow20: '#FEDF89',
  yellow30: '#FEC84B',
  yellow40: '#FDB022',
  yellow50: '#F79009',
  yellow60: '#DC6803',
  yellow70: '#B54708',
  yellow80: '#93370D',
  yellow90: '#7A2E0E',
};
