import { logger } from '../logger.js';
import { safeStringify } from '@contra/utilities/safeStringify';
import { serializeError } from '@contra/utilities/serializeError';
import { captureMessage } from '@sentry/nextjs';
import getConfig from 'next/config';
import {
  type GraphQLResponse,
  Network,
  QueryResponseCache,
} from 'relay-runtime';
import {
  type ConcreteRequest,
  type RequestParameters,
} from 'relay-runtime/lib/util/RelayConcreteNode';
import {
  type CacheConfig,
  type Variables,
} from 'relay-runtime/lib/util/RelayRuntimeTypes';

const { publicRuntimeConfig } = getConfig();

const log = logger.child({
  namespace: 'relay:environment',
});

const ONE_MINUTE = 60 * 1_000;
const MAX_RETRIES = 4;
const MAX_RETRY_DELAY_MS = 10_000;

const wait = async (ms: number) => {
  return await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const networkFetch = async (
  operation: RequestParameters,
  variables: Variables,
  retries = MAX_RETRIES,
): Promise<GraphQLResponse> => {
  try {
    const response = await fetch(
      typeof window === 'undefined' ? `${publicRuntimeConfig.apiUrl}` : '/api',
      {
        body: safeStringify({
          query: operation.text,
          variables,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-cwa-release': publicRuntimeConfig.releaseVersion,
        },
        method: 'POST',
      },
    );

    if (!response.ok) {
      if (retries > 0) {
        log.trace(
          {
            response: JSON.parse(safeStringify(response)),
          },
          'Relay fetchQuery failed, retrying...',
        );

        await wait(MAX_RETRY_DELAY_MS / retries);

        return await networkFetch(operation, variables, retries - 1);
      }

      log.error(
        {
          response: JSON.parse(safeStringify(response)),
        },
        'Failed to fetch query from API after max retries',
      );

      captureMessage(`Failed to fetch query after ${MAX_RETRIES} retries`, {
        contexts: {
          response: {
            body: await response.text(),
            headers: Object.fromEntries(response.headers.entries()),
            redirected: response.redirected,
            status: response.status,
            statusText: response.statusText,
            type: response.type,
            url: response.url,
          },
        },
        level: 'info',
      });

      throw new Error(
        `Failed to fetch query: ${[response.status, response.statusText].join(
          ' - ',
        )}`,
      );
    }

    try {
      const json = await response.json();
      return json;
    } catch (error: unknown) {
      log.error(
        {
          error: serializeError(error),
        },
        'Failed to parse response JSON',
      );

      throw error;
    }
  } catch (error) {
    log.error(
      { error: serializeError(error) },
      'Network error. Failed to fetch.',
    );

    throw error;
  }
};

export const createNetwork = () => {
  const cache = new QueryResponseCache({ size: 250, ttl: ONE_MINUTE });

  const fetchQuery = async (
    operation: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
  ): Promise<GraphQLResponse> => {
    /**
     * We're deep(ish)ly cloning variables here to ensure that, if any variables were
     * passed as a reference to a mutable object, the variables don't change between
     * retries (if a retry is called), for example, if we change route slugs that would
     * query different data from Relay while a mutation is still running and retries,
     * this adds an extra layer of protection to ensure that variables is not accidentally
     * mutated and submits data we don't want it to submit.
     *
     * See: https://contra-work.slack.com/archives/C01E15C2XE3/p1644001276097249
     */
    const clonedVariables = {
      ...variables,
      input:
        // eslint-disable-next-line @typescript-eslint/dot-notation
        typeof variables['input'] === 'object'
          ? // eslint-disable-next-line @typescript-eslint/dot-notation
            { ...variables['input'] }
          : undefined,
    };
    const queryId = operation.text ?? '';
    const isMutation = operation.operationKind === 'mutation';
    const isQuery = operation.operationKind === 'query';
    const forceFetch = cacheConfig?.force;

    const fromCache = cache.get(queryId, clonedVariables);

    if (isQuery && fromCache !== null && !forceFetch) {
      return fromCache;
    }

    try {
      const json = await networkFetch(operation, clonedVariables);

      if (isQuery && json && queryId !== '') {
        cache.set(queryId, variables, json);
      }

      if (isMutation) {
        cache.clear();
      }

      return json;
    } catch (error: unknown) {
      log.error(
        {
          error: serializeError(error),
        },
        'Failed to parse response JSON',
      );

      throw error;
    }
  };

  const network = Network.create(fetchQuery);
  // @ts-expect-error - Overwriting cache hackery
  network.responseCache = cache;
  return network;
};

export const getPreloadedQuery = async (
  { params }: ConcreteRequest,
  variables: Variables,
) => {
  const response = await networkFetch(params, variables);
  return {
    params,
    response,
    variables,
  };
};
