import { IconSvg, type IconSvgProps } from './IconSvg.js';
import { createElement } from 'react';

export const ContraLogoSuspenseLoaderIcon = (props: IconSvgProps) =>
  createElement(
    IconSvg,
    {
      ...props,
      viewBox: '0 0 60 60',
    },
    <path
      clipRule="evenodd"
      d="M32.883.56h-.678v27.255h27.238v-.836C46.493 23.65 36.288 13.485 32.883.56Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="M32.765 27.256H58.33C46.124 23.764 36.495 14.303 32.765 2.178v25.078Zm26.677 1.118H32.203a.559.559 0 0 1-.559-.56V.56c0-.309.25-.559.56-.559h.678a.56.56 0 0 1 .54.417c3.363 12.765 13.386 22.736 26.16 26.02a.56.56 0 0 1 .419.542v.836c0 .308-.25.559-.56.559ZM59.444 32.864v-.679H32.205v27.256h.835c3.328-12.958 13.486-23.17 26.404-26.577Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="M32.765 32.744v25.58a37.6 37.6 0 0 1 25.063-25.58H32.765ZM33.039 60h-.836a.559.559 0 0 1-.559-.56V32.185c0-.308.25-.558.56-.558h27.238c.308 0 .559.25.559.558v.679a.56.56 0 0 1-.416.54A36.476 36.476 0 0 0 33.58 59.58a.558.558 0 0 1-.541.42ZM27.12 59.44h.68V32.186H.56v.836c12.95 3.33 23.155 13.493 26.56 26.42Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="M1.666 32.744c12.207 3.492 21.836 12.953 25.566 25.078V32.744H1.666ZM27.796 60h-.677a.56.56 0 0 1-.54-.417C23.216 46.817 13.192 36.847.419 33.563A.56.56 0 0 1 0 33.02v-.835c0-.308.25-.559.559-.559h27.238c.308 0 .558.25.558.56V59.44c0 .31-.25.56-.558.56ZM.56 27.136v.678H27.8V.56h-.836C23.636 13.517 13.479 23.729.56 27.136Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="M2.179 27.255h25.062V1.674A37.602 37.602 0 0 1 2.179 27.255Zm25.618 1.119H.559a.559.559 0 0 1-.559-.56v-.677c0-.255.17-.477.416-.54A36.477 36.477 0 0 0 26.421.42a.56.56 0 0 1 .54-.42h.836c.308 0 .558.25.558.56v27.255c0 .308-.25.559-.558.559Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  );
