import {
  type BaseSyntheticEvent,
  type CSSProperties,
  type ReactNode,
} from 'react';

/**
 * Intentionally not using SVGSVGElement props
 * to restrict how icons can be used.
 */
export type IconSvgProps = {
  readonly 'aria-hidden'?: boolean;
  readonly 'aria-label'?: string;
  readonly className?: string;
  readonly color?: string;
  readonly fill?: string;
  readonly focusable?: boolean;
  readonly onClick?: (event: BaseSyntheticEvent) => void;
  readonly role?: 'button' | 'img' | 'presentation';
  readonly size?: number | '100%';
  readonly stroke?: string;
  readonly strokeWidth?: number;
  readonly style?: CSSProperties;
};

export const IconSvg = ({
  children,
  role = 'img',
  size = 24,
  strokeWidth = 1,
  viewBox,
  ...props
}: IconSvgProps & {
  readonly children?: ReactNode;
  readonly viewBox: string;
}) => {
  return (
    <svg
      fill="none"
      focusable={false}
      height={size}
      role={role}
      strokeWidth={strokeWidth}
      viewBox={viewBox}
      width={size}
      {...props}
    >
      {children}
    </svg>
  );
};
