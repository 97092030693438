import { useIsPlaywright } from '@/hooks/useIsPlaywright.js';
// eslint-disable-next-line canonical/no-restricted-imports
import { motion, type MotionProps } from 'framer-motion';
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
  useMemo,
} from 'react';

// We should be able to discard this component when framer adds own implementation
// https://github.com/framer/motion/issues/1604
const useAnimatedProps = ({ ...props }) => {
  const isPlaywright = useIsPlaywright();
  return useMemo(() => {
    return isPlaywright
      ? {
          ...props,
          animate: undefined,
          initial: undefined,
          transition: undefined,
          variants: undefined,
          whileDrag: undefined,
          whileFocus: undefined,
          whileHover: undefined,
          whileInView: undefined,
          whileTap: undefined,
        }
      : props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
};

type BaseAnimatedProps<T extends React.ElementType> = MotionProps &
  Omit<ComponentPropsWithoutRef<T>, 'style'>;

const Anchor = forwardRef(
  (
    { ...props }: BaseAnimatedProps<'a'>,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.a
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

const Div = forwardRef(
  (
    { ...props }: BaseAnimatedProps<'div'>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.div
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

const H5 = forwardRef(
  (
    { ...props }: BaseAnimatedProps<'h5'>,
    ref: ForwardedRef<HTMLHeadingElement>,
  ) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.h5
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

const Nav = forwardRef(
  ({ ...props }: BaseAnimatedProps<'nav'>, ref: ForwardedRef<HTMLElement>) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.nav
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

const Span = forwardRef(
  (
    { ...props }: BaseAnimatedProps<'span'>,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.span
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

const Button = forwardRef(
  (
    { ...props }: BaseAnimatedProps<'button'>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.button
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

const Path = forwardRef(
  (
    { ...props }: BaseAnimatedProps<'path'>,
    ref: ForwardedRef<SVGPathElement>,
  ) => {
    const animatedProps = useAnimatedProps(props);
    return (
      <motion.path
        ref={ref}
        {...animatedProps}
      />
    );
  },
);

export const animated = {
  a: Anchor,
  button: Button,
  div: Div,
  h5: H5,
  nav: Nav,
  path: Path,
  span: Span,
};
