/* eslint-disable canonical/filename-match-exported */
import { SvgDefs } from '@/components/SvgDefs/SvgDefs.js';
import { globalStyles } from '@/global.styles.js';
import {
  type PreloadedQueries,
  RelayContainer,
} from '@/services/relay/RelayContainer.js';
import {
  onRouteChangeComplete,
  onRouteChangeError,
  onRouteChangeStart,
} from '@/utilities/routerEvents.js';
import { type AppProps } from 'next/app';
import getConfig from 'next/config';
import { Router } from 'next/router';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig();

/**
 * Handle Next Router events in a central location.
 */
Router.events.on('routeChangeComplete', onRouteChangeComplete);
Router.events.on('routeChangeError', onRouteChangeError);
Router.events.on('routeChangeStart', onRouteChangeStart);

const CustomApp = ({ Component, pageProps }: AppProps<PreloadedQueries>) => {
  globalStyles();

  return (
    <>
      {publicRuntimeConfig?.googleMeasurementId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig?.googleMeasurementId}`}
          />
          <Script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${publicRuntimeConfig.googleMeasurementId}');
              `,
            }}
            id="google-measurement"
          />
        </>
      )}
      <SvgDefs />
      <RelayContainer
        Component={Component}
        props={pageProps}
      />
    </>
  );
};

export default CustomApp;
