export const fontFace = [
  /* cyrillic-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F::after',
  },
  /* cyrillic */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116::after',
  },
  /* greek-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+1F00-1FFF::after',
  },
  /* greek */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0370-03FF::after',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB::after',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF::after',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD::after',
  },
  /* cyrillic-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F::after',
  },
  /* cyrillic */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116::after',
  },
  /* greek-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+1F00-1FFF::after',
  },
  /* greek */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0370-03FF::after',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB::after',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF::after',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD::after',
  },
  /* cyrillic-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F::after',
  },
  /* cyrillic */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116::after',
  },
  /* greek-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+1F00-1FFF::after',
  },
  /* greek */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0370-03FF::after',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB::after',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF::after',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD::after',
  },
  /* cyrillic-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F::after',
  },
  /* cyrillic */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116::after',
  },
  /* greek-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+1F00-1FFF::after',
  },
  /* greek */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+0370-03FF::after',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB::after',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF::after',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD::after',
  },
  // Inter Black
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    src: 'url("https://fonts.gstatic.com/l/font?kit=UcC73FwrK3iLTeHuS_fvQtMwCp50KnMq15j8eUYD7vU3m1D5N79ppJ-rt8L6VgG8K7R-GlAIiBAUQ8kEPx5ANaqD9I4_z1hr1k27DJRNJw_84QrQJH63-gYYeDfCHYxHJHZM71bLvr7Yd3qY5i5ZYVORl8JGl0onESgWaU4GaW1XHbnRu2z_FxNfqWNKOa3m&skey=c491285d6722e4fa&v=v3") format("woff2")',
    unicodeRange: 'U+?????',
  },

  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Clash',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/clashdisplay-regular-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Clash',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/clashdisplay-medium-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Clash',
    fontStyle: 'normal',
    fontWeight: 300,
    src: 'url("/fonts/clashdisplay-light-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Clash',
    fontStyle: 'normal',
    fontWeight: 200,
    src: 'url("/fonts/clashdisplay-extralight-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Clash',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("/fonts/clashdisplay-semibold-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Clash',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("/fonts/clashdisplay-bold-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 300,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 300,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 300,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  /* vietnamese */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
  },
  /* latin-ext */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2") format("woff2")',
    unicodeRange:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  },
  /* latin */
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2") format("woff2")',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/satoshi.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/satoshi.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 300,
    src: 'url("/fonts/satoshi.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 200,
    src: 'url("/fonts/satoshi.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("/fonts/satoshi.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("/fonts/satoshi.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Cabinet',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/CabinetGrotesk-Regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Cabinet',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/CabinetGrotesk-Medium.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Cabinet',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("/fonts/CabinetGrotesk-Bold.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Cabinet',
    fontStyle: 'normal',
    fontWeight: 900,
    src: 'url("/fonts/CabinetGrotesk-Black.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Gambarino',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/gambarino.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Panchang',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("/fonts/panchang-semibold.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Panchang',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("/fonts/panchang-bold-webfont.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Anton',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/Anton-Regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Newsreader',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/newsreader-regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/karla-regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/karla-medium.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Lora',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/lora-regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Lora',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/lora-medium.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/generalsans-regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/generalsans-medium.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    src: 'url("/fonts/generalsans-semibold.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/spacegrotesk-regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("/fonts/spacegrotesk-bold.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Zodiak',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/zodiak-regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Switzer',
    fontStyle: 'normal',
    fontWeight: '100 900',
    src: "url('/fonts/switzer-variable.woff2') format('woff2')",
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Switzer',
    fontStyle: 'italic',
    fontWeight: '100 900',
    src: "url('/fonts/switzer-variable-italic.woff2') format('woff2')",
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Syne',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/syne-medium.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Epilogue',
    fontStyle: 'normal',
    fontWeight: '100 900',
    src: "url('/fonts/epilogue-variable.woff2') format('woff2')",
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Epilogue',
    fontStyle: 'italic',
    fontWeight: '100 900',
    src: "url('/fonts/epilogue-variable-italic.woff2') format('woff2')",
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Neco',
    fontStyle: 'normal',
    fontWeight: '400 900',
    src: "url('/fonts/neco-variable.woff2') format('woff2')",
  },
  {
    fontDisplay: 'swap' as const,
    fontFamily: 'Neco',
    fontStyle: 'italic',
    fontWeight: '400 900',
    src: "url('/fonts/neco-variable-italic.woff2') format('woff2')",
  },
  {
    fontFamily: 'Butler',
    fontStyle: 'normal',
    fontWeight: 100,
    src: 'url("/fonts/butler-light.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontFamily: 'Bricolage',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/Bricolage-Grotesque-Regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontFamily: 'Gilda',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/Gilda-Display-Regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontFamily: 'Archivo',
    fontStyle: 'normal',
    fontWeight: 300,
    src: 'url("/fonts/Archivo-Light.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontFamily: 'Archivo',
    fontStyle: 'normal',
    fontWeight: 400,
    src: 'url("/fonts/Archivo-Regular.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontFamily: 'Archivo',
    fontStyle: 'normal',
    fontWeight: 500,
    src: 'url("/fonts/Archivo-Medium.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
  {
    fontFamily: 'Archivo',
    fontStyle: 'normal',
    fontWeight: 700,
    src: 'url("/fonts/Archivo-Bold.woff2") format("woff2")',
    unicodeRange: 'U+?????',
  },
];
