import * as NProgress from 'nprogress';

const setSmoothScroll = (isSmooth: boolean) => {
  if (typeof window !== 'undefined') {
    document.documentElement.style.scrollBehavior = isSmooth
      ? 'smooth'
      : 'auto';
  }
};

export const onRouteChangeComplete = () => {
  NProgress.done();
  setSmoothScroll(true);
};

export const onRouteChangeError = () => {
  NProgress.done();
  setSmoothScroll(true);
};

export const onRouteChangeStart = () => {
  NProgress.start();
  setSmoothScroll(false);
};
