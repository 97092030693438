/* eslint-disable @contra/enforce-stitches-tokens */
import { colors } from './colorPrimitives.js';
import { fonts } from './fonts.js';
import { letterSpacings } from './letterSpacings.js';
import { getMediaQueries } from './media.js';
import { shadows } from './shadows.js';
import { sizes } from './sizes.js';
import { fillRelativeParentContainer } from './utils/fillRelativeParentContainer.js';
import { linearGradient } from './utils/linearGradient.js';
import { screenReaderOnly } from './utils/screenReaderOnly.js';
import { zIndices } from './zIndices.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { createStitches } from '@stitches/react';

export const {
  config,
  createTheme,
  css,
  getCssText,
  globalCss,
  keyframes,
  styled,
  theme,
} = createStitches({
  media: getMediaQueries(),
  theme: {
    borderWidths: {
      '0_5': '0.5px',
      1: '1px',
      '1_5': '1.5px',
      2: '2px',
      3: '3px',
      4: '4px',
      navbarWWM: '$safe1px',
    },
    colors,
    fonts,
    fontSizes: {
      ...sizes,
    },
    fontWeights: {
      body: 500,
      bold: 700,
      heading: 500,
      heavy: 800,
      light: 300,
      medium: 500,
      regular: 400,
      semiBold: 600,
    },
    height: {
      ...sizes,
    },
    letterSpacings,
    lineHeights: {
      280: pxToRem(280),
      body: 1.1,
      heading: 1.2,
    },
    radii: {
      3: pxToRem(3),
      4: pxToRem(4),
      5: pxToRem(5),
      6: pxToRem(6),
      8: pxToRem(8),
      10: pxToRem(10),
      16: pxToRem(16),
      20: pxToRem(20),
      24: pxToRem(24),
      25: pxToRem(25),
      30: pxToRem(30),
      32: pxToRem(32),
      40: pxToRem(40),
      50: pxToRem(50),
      60: pxToRem(60),
      68: pxToRem(68),
    },
    shadows,
    sizes: {
      ...sizes,
      themeButtonHeight: '$54',
    },
    space: {
      ...sizes,
      desktopLeftGutter: '$48',
      themeButtonPadding: '$16 $24',
    },
    width: {
      ...sizes,
    },
    zIndices,
  },
  utils: {
    fillRelativeParentContainer,
    linearGradient,
    screenReaderOnly,
  },
});

export type StitchesTheme = typeof theme;
