/* eslint-disable @contra/enforce-stitches-tokens */
import { pxToRem } from '@/utilities/pxToRem.js';

export const letterSpacings = {
  '0_2': pxToRem(0.2),
  '0_5': pxToRem(0.5),
  '0_15': pxToRem(0.15),
  '0_25': pxToRem(0.25),
  '0_31': pxToRem(0.31),
  '0_42': pxToRem(0.42),
  '0_75': pxToRem(0.75),
  '1': pxToRem(1),
  '1_2': pxToRem(1.2),
  '1_5': pxToRem(1.5),
  2: pxToRem(2),
  '2_4': pxToRem(0.2),
  3: pxToRem(3),
  safe1px: `max(${pxToRem(1)}, 1px)`,
};
